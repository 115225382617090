<template>
  <div class="bg-gray-10 text-dark px-4 md:px-0 py-10 md:py-16">
    <!-- eslint-disable vue/no-v-html -->
    <div class="max-w-wrapper-sm w-full mx-auto">
      <p
        v-if="!_isEmpty(title) && !_isEmpty(activeTab?.label)"
        class="lg:text-center text-2xl md:text-3.5xl/12 mb-6 md:mb-10"
        v-html="title?.[activeTab?.label] || title"
      >
      </p>

      <p
        v-if="!_isEmpty(subHeader) && !_isEmpty(activeTab?.label)"
        class="text-base md:text-lg md:text-center mb-6 md:mb-8"
        v-html="subHeader?.[activeTab?.label] || subHeader"
      >
      </p>

      <atoms-tabs
        ref="tabsRef"
        :tabs="tabs"
      >
        <template
          v-for="(tab, tabKey) in tabs"
          :key="tabKey"
          #[`${tab.key}`]
        >
          <div class="border border-gray-400 rounded-r-lg rounded-bl-lg overflow-hidden">
            <div
              v-for="(item, itemKey) in list[tab.label]"
              :key="itemKey"
              class="border-b border-gray-400 last:border-0 text-sm"
            >
              <div class="flex bg-gray-350 min-h-[6.5rem] md:min-h-[4.5rem]">
                <div class="flex md:items-center w-[9.0625rem] max-w-[9.0625rem] md:w-[12.5rem] md:max-w-[12.5rem] font-bold px-4 py-[0.6875rem]">
                  {{ itemKey }}
                </div>

                <div class="flex flex-1 md:items-center bg-white p-3 md:py-4 md:pl-6 md:pr-9" v-html="item">
                </div>
              </div>
            </div>
          </div>
        </template>
      </atoms-tabs>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'MoleulesTableTabs'
})

const props = defineProps({
  title: {
    type: [String, Object],
    default: ''
  },

  subHeader: {
    type: [String, Object],
    default: ''
  },

  theme: {
    type: String,
    default: 'dark',
    validator: value => ['light', 'dark'].includes(value)
  },

  tabWrapperClass: {
    type: String,
    default: ''
  },

  tabClass: {
    type: String,
    default: ''
  },

  tabClassActive: {
    type: String,
    default: ''
  },

  tabsFullWidth: {
    type: Boolean,
    default: false
  },

  tabs: {
    type: Array,
    default: () => (['Tab 1', 'Tab 2'])
  },

  list: {
    type: Object,
    default: () => ({})
  },

  // should be kebab-case
  forceCurrTab: {
    type: String,
    default: ''
  }
})

const tabsRef = ref()
const activeTab = computed(() => {
  const curr = tabsRef.value?.currTab

  return props.tabs.find(item => item.key === curr)
})

</script>
